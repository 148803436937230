<template>
    <section v-if="shouldDisplayLinks || shouldDisplayNetworks || shouldDisplayButton" class="subnav-socials-wrapper">
        <div class="subnav-socials">
            <PageControls :noBorder="true" class="page-controls">
                <template #left>
                    <div v-if="shouldDisplayLinks" class="subnav">
                        <template v-for="(item, index) in navigationLinks">
                            <Anchor
                                v-if="!item.hasSublinks"
                                :key="`anchor-${index}`"
                                :url="item.reference?.path + '?lang=' + pageLang"
                                class="nav-link"
                                :class="item.reference?.path === route?.path ? 'active' : ''"
                                >{{ item?.label }}</Anchor
                            >
                            <DropdownMenu
                                v-else
                                :items="item?.sublinks"
                                menuName="translation-pdfs"
                                labelVariant="body-large"
                                class="control nav-link"
                                :label="item?.label"
                                :key="index"
                            >
                            </DropdownMenu>
                        </template>
                    </div>
                </template>

                <template #right>
                    <slot name="right-content">
                        <div v-if="shouldDisplayButton" class="button">
                            <CarnegieButton v-bind="button"></CarnegieButton>
                        </div>

                        <div v-if="shouldDisplayNetworks" class="socials">
                            <IconLink
                                v-for="(social, index) in socialNetworks"
                                :icon="social.network"
                                :destination="getSocialUrl(social.handle, social.network)"
                                theme="light"
                                :overrideColor="props?.accentColor"
                                :key="index"
                            />
                        </div>
                    </slot>
                </template>
            </PageControls>
        </div>
    </section>
</template>
<script setup>
const props = defineProps({
    socialNetworks: {
        type: Array,
        default: () => [],
    },
    navigationLinks: {
        type: Array,
        default: () => [],
    },
    accentColor: {
        type: String,
    },
    button: {
        type: Object,
    },
    pageLanguage: {
        type: String,
        default: 'en',
    },
});
const route = useRoute();
const pageLang = computed(() => {
    if (props.pageLanguage === '') {
        const { pageLanguage } = usePageDataStore();
        return pageLanguage;
    } else return props.pageLanguage;
});

const shouldDisplayLinks = computed(() => {
    return !!props.navigationLinks?.length;
});

const shouldDisplayNetworks = computed(() => {
    return !!props.socialNetworks?.length;
});

const shouldDisplayButton = computed(() => {
    return !!(props.button?.label && (props.button?.url || props.button?.reference?.path || props.button?.file?.url));
});
</script>

<style lang="scss" scoped>
.subnav-socials-wrapper {
    border-bottom: 0.1rem solid palette(border-light);

    .page-controls {
        width: 100%;
    }
}

.subnav-socials {
    @include content-section;

    @include media-query(phone) {
        .layout {
            display: grid;
        }
        :deep(.right) {
            display: grid;
        }
        .button,
        .socials {
            flex: unset;
        }
    }

    @include media-query(phone-mw) {
        display: flex;
        min-height: 8rem;
        align-items: center;
    }
}

.subnav {
    display: flex;
    gap: 2rem;
    justify-content: flex-start;
    align-items: center;

    @include media-query(phone) {
        text-wrap: nowrap;
        overflow: auto;
        position: relative;
        display: grid;
    }

    .nav-link {
        color: color(blue, dark, 0.7);

        &.active {
            color: color(blue, dark);
        }
    }
}

.button,
.socials {
    flex: 1 0 auto;
}

.socials {
    display: flex;
    align-items: center;
    gap: 0.8rem;
}
</style>
